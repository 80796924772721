import { enviaEmail } from '../../api/ezcale/notificacoes';
import { atualizaAcordo } from "../../api/backend-csi/acordo";

const atualizaStatusAcordo = async (status, setIsLoading, acordo, condominio, contraProposta = null) => {
    setIsLoading(true);
    const updateData = {
        status,
        id: acordo.id,
        contraProposta
    }
    await atualizaAcordo(updateData, acordo.hash_cobranca_original);
    notificaAdministradores(acordo.hash_cobranca_original, status, acordo, condominio, contraProposta);
    window.location.reload();
}

const notificaAdministradores = async (hashOriginalInvoiceId, status, acordo, condominio, contraProposta) => {

    let htmlBody = `<p>O Acordo do Condomínio e Unidade ${acordo.descricao_cobranca_original} foi ${status} pelo Síndico ${condominio.nome_sindico}</p>
    <p>Carteira do Acordo: ${window.location.origin + '/cobrancas/' + acordo.hash_carteira}</p>`;
    if(contraProposta != null) {
        htmlBody += `<br /><p>Motivo: ${contraProposta}</p>`
    }
    const subject = `Acordo - ${acordo.descricao_cobranca_original} foi ${status} pelo Síndico`;
    const recipient = { ['adm']: localStorage.getItem('loggedUserEmail') };

    await enviaEmail(htmlBody, subject, recipient, hashOriginalInvoiceId)
}

export {
    atualizaStatusAcordo,
    notificaAdministradores
}