import React, { useState, useEffect, lazy, Suspense } from "react";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import OpenIcon from "@mui/icons-material/FolderOpen";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TuneIcon from "@mui/icons-material/Tune"; // Ícone de configurações
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Card,
  CardContent,
  Typography,
  Button,
  Alert,
  AlertTitle,
  Divider,
  Box,
  TextField
} from "@mui/material";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getColorProfile } from '../../utils/appColors';
import TextWithDoubleBorder from "../../components/TextWithDoubleBorder";
import handleBuscaCarteiras from './BuscaCarteiraService';
import CircularLoaderWithCenterLogo from '../../components/Loader/CircularLoaderWithCenterLogo';
import { getInvoiceStatusButton } from '../../utils/statusButton';
import { formataDataHoraMySQLParaDataBrasileira } from '../../utils/dateFunctions';
import DateRangePicker from "../../utils/DateRangePicker";
import { formatDate, subtraiXDiasDeHoje } from "../../utils/dateFunctions";

// Lazy Loading for modals
const CriarCarteiraModal = lazy(() => import('./CriarCarteiraModal'));
const BuscaCarteirasModal = lazy(() => import('./BuscaCarteirasModal'));

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

export default function Carteiras() {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false); // Estado para o modal de busca
  const { corPrincipal,
    corPrincipalEscurecida,
    corSecundaria,
    corSecundariaEscurecida,
    corSecundariaMaisClara } = getColorProfile();
  const [carteiras, setCarteiras] = useState([]);
  const [todasCarteiras, setTodasCarteiras] = useState([]);
  const [alerta, setAlerta] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchTerm, setSearchTerm] = useState('');  // Estado para o termo de busca
  const [buscaCarteirasData, setBuscaCarteirasData] = useState({
    dateto: formatDate(new Date()),
    datefrom: formatDate(new Date(new Date().setDate(new Date().getDate() - 360))),
    hashid: null,
    name: null
  });
  //Utilizado no DatePicker
  const [dateto, setDateto] = useState(formatDate(new Date()));
  const [datefrom, setDatefrom] = useState(subtraiXDiasDeHoje(7));
  const [selection, setSelection] = useState('Últimos 7 Dias');

  useEffect(() => {
    const newBuscaCarteiraData = { ...buscaCarteirasData };
    newBuscaCarteiraData.dateto = dateto;
    newBuscaCarteiraData.datefrom = datefrom;
    handleBuscaCarteiras(newBuscaCarteiraData, setIsLoading, setAlerta, setCarteiras, setTodasCarteiras);
  }, [dateto, datefrom]);

  const handleDelete = (id) => {
    if (window.confirm("Tem certeza que deseja excluir esta carteira?")) {
      console.log("Deletando carteira com ID:", id);
    }
  };

  const handleDateChange = async (range) => {
    setDatefrom(range.datefrom);
    setDateto(range.dateto);
  }

  // Função de filtro
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const cobrancasParafiltro = [...todasCarteiras];
    const filtered = cobrancasParafiltro.filter((item) =>
      item.name?.toLowerCase().includes(term) ||
      item.descriptor?.toLowerCase().includes(term) ||
      item.createdByUsername?.toLowerCase().includes(term)
    );

    setCarteiras(filtered);
  };

  const handleCreateWalletClick = () => {
    setModalOpen(true);
  };

  const handleSearchModalOpen = () => {
    setSearchModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeSearchModal = () => {
    setSearchModalOpen(false);
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);

    const sortedData = [...carteiras].sort((a, b) => {
      const valA = a[column].trim();
      const valB = b[column].trim();

      // Checa se os valores são numéricos
      const isNumeric = !isNaN(valA) && !isNaN(valB);

      // Checa se os valores são datas
      const isDate = !isNaN(Date.parse(valA)) && !isNaN(Date.parse(valB));

      let comparison = 0;

      if (isNumeric) {
        // Comparação para números
        comparison = Number(valA) - Number(valB);
      } else if (isDate) {
        // Comparação para datas
        comparison = new Date(valA) - new Date(valB);
      } else {
        // Comparação para strings com números embutidos
        const compareStringsWithNumbers = (str1, str2) => {
          const regex = /(\d+|\D+)/g; // Regexp para dividir a string em números e letras
          const parts1 = str1.match(regex);
          const parts2 = str2.match(regex);

          // Compara as partes separadas (números como inteiros e texto como strings)
          for (let i = 0; i < Math.min(parts1.length, parts2.length); i++) {
            const part1 = parts1[i];
            const part2 = parts2[i];

            const num1 = parseInt(part1, 10);
            const num2 = parseInt(part2, 10);

            // Se ambos são números, compara numericamente
            if (!isNaN(num1) && !isNaN(num2)) {
              if (num1 !== num2) {
                return num1 - num2;
              }
            } else {
              // Caso contrário, compara lexicograficamente
              const textComparison = part1.localeCompare(part2);
              if (textComparison !== 0) {
                return textComparison;
              }
            }
          }

          // Se todos os segmentos forem iguais, compara pelo comprimento da string
          return parts1.length - parts2.length;
        };

        comparison = compareStringsWithNumbers(valA, valB);
      }

      return isAsc ? comparison : -comparison;
    });

    setCarteiras(sortedData);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="w-full flex flex-col items-center">
        {!isLoading ? (
          <>
            <div className="w-full">
              <Typography variant="h4" component="h4" sx={{ color: corPrincipalEscurecida }}>
                Carteiras
              </Typography>
              <div
                className="mb-2"
                style={{ borderBottom: `3px solid ${corPrincipalEscurecida}` }} />
            </div>
            {/* Separador acima */}
            <Divider className="mb-2" sx={{ bgcolor: corPrincipalEscurecida }} />
            <Box className="flex flex-col md:flex-row md:justify-between items-center w-full space-y-3 md:space-y-1">

              {/* Campo de busca estilizado */}


              {/* Botão de Configuração com ícone
              <IconButton className="ml-4 hover:bg-gray-200">
                <SettingsIcon style={{ fontSize: 30, color: '#616161' }} />
              </IconButton> */}
            </Box>
            <div className="w-full flex justify-between items-center mb-4">
              {/* Botão Criar Carteira */}
              <Button
                variant="contained"
                sx={{
                  background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                  color: "white",
                  borderRadius: "6px",
                  padding: "0px 20px",
                  fontSize: "1rem",
                  height: "35px"
                }}
                onClick={handleCreateWalletClick}
              >
                Criar Carteira
              </Button>
            </div>
            <div className="w-full flex justify-between items-center mb-4">
              <DateRangePicker
                onDateChange={(range) => handleDateChange(range)}
                dateTo={dateto}
                dateFrom={datefrom}
                setDateTo={setDateto}
                setDateFrom={setDatefrom}
                selection={selection}
                setSelection={setSelection} />
              {/* Ícone de Configurações */}
              {/* <IconButton onClick={handleSearchModalOpen}>
                <TuneIcon sx={{ color: corPrincipalEscurecida }} />
              </IconButton> */}
              <TextField
                className="flex-shrink-0 shadow-sm w-full max-w-xs pt-[500px]"
                variant="outlined"
                placeholder="Procurar"
                value={searchTerm}
                onChange={handleSearch}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '40px', // Altura personalizada
                    width: '90%', // Largura mínima
                    backgroundColor: '#f7f7f7', // Cor de fundo customizada
                    borderRadius: '8px', // Borda arredondada
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: corPrincipalEscurecida, // Cor personalizada escurecida no hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: corPrincipal, // Cor personalizada quando o campo está em foco
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: corPrincipal, // Cor padrão
                  },
                }}
              />
            </div>

            {/* Mostra Alerta de Resultado do Processamento */}
            {Object.keys(alerta).length > 0 && (
              <Alert className="signin-area" severity={alerta.type} key={`id-${alerta.titulo}`}>
                <AlertTitle>{alerta.titulo}</AlertTitle>
                {alerta.descricao}
              </Alert>
            )}

            {/* Mobile version: Display as Cards */}
            <div className="lg:hidden w-full space-y-3 mt-4">
              {carteiras.map((carteira, index) => {
                const backgroundColor = index % 2 === 0 ? '#FFF9F5' : '#F5F9FF';
                return (< Card
                  key={carteiras.hashId}
                  style={{
                    backgroundColor,
                    width: "100%",
                    boxShadow: 3,
                    borderRadius: "16px",
                  }}
                >
                  <CardContent >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <WalletIcon className="text-gray-700" sx={{ color: corPrincipalEscurecida }} />
                        <TextWithDoubleBorder text={carteira.name} innerColor={corPrincipalEscurecida} outerColor='black' borderWidth='1.5' />
                      </div>
                    </div>
                    <div className="mt-4">
                      <Typography color="textSecondary">{carteira.descriptor}</Typography>
                      <Typography variant="body2">Tipo: {carteira.idType}</Typography>
                      <Typography variant="body2">Criado por: {carteira.createdByUsername}</Typography>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex justify-start">
                        {getInvoiceStatusButton(carteira.status)}
                      </div>
                      <div className="flex space-x-1">
                        <Link to={'/cobrancas/' + carteira.hashId}>
                          <IconButton >
                            <OpenIcon className="text-blue-500" />
                          </IconButton>
                        </Link>
                        {/* <IconButton>
                          <EditIcon className="text-green-500" />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(carteira.hashId)} className="text-red-500">
                          <DeleteIcon className="text-red-500" />
                        </IconButton> */}
                      </div>
                    </div>
                  </CardContent>
                </Card>)
              })}
            </div>

            {/* Desktop version: Display as Table */}
            <TableContainer component={Paper} className="hidden lg:block w-[80%] shadow-lg">
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: corPrincipal, color: 'white' }}>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("createdAt")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Criado Em</b> {sortColumn === "createdAt" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("name")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Nome</b> {sortColumn === "name" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("descriptor")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Descrição</b> {sortColumn === "descriptor" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("createdByUsername")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Criado por</b> {sortColumn === "createdByUsername" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("status")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Status</b> {sortColumn === "status" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: 'white' }}>
                      <b>Ação</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {carteiras.map((carteira, index) => {
                    const backgroundColor = index % 2 === 0 ? '#FFF9F5' : '#F5F9FF';
                    return (
                      <TableRow key={carteira.hashId} sx={{ backgroundColor, '&:hover': { backgroundColor: '#e0e0e0' } }}>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {formataDataHoraMySQLParaDataBrasileira(carteira.createdAt)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {carteira.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {carteira.descriptor}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {carteira.createdByUsername}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {getInvoiceStatusButton(carteira.status)}
                        </TableCell>
                        <TableCell align="center">
                          <Link to={'/cobrancas/' + carteira.hashId}>
                            <IconButton>
                              <OpenIcon sx={{ color: corPrincipalEscurecida }} />
                            </IconButton>
                          </Link>
                          {/* <IconButton>
                            <EditIcon sx={{ color: corPrincipalEscurecida }} />
                          </IconButton>
                          <IconButton onClick={() => handleDelete(carteira.hashId)} sx={{ color: corPrincipalEscurecida }}>
                            <DeleteIcon />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>)
                  }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
          </div>
        )}
        {/* Modal de Criação de Carteira */}
        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          {modalOpen && <CriarCarteiraModal open={modalOpen} onClose={closeModal} />}
        </Suspense>

        {/* Modal de Busca */}
        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          {searchModalOpen && <BuscaCarteirasModal open={searchModalOpen} onClose={closeSearchModal} />}
        </Suspense>
      </div>
    </ThemeProvider>
  );
}
