import axios from 'axios';
import { armazenaEmailUsuarioLogadoPeloTaxId } from './customer';



async function login(loginData) {
    loginData = {
        secret_key: process.env.REACT_APP_API_EZCALE_SECRET_KEY,
        action: 'login',
        ...loginData,
    }
    try {
        const { data } = await axios.post(process.env.REACT_APP_API_EZCALE + "/login.php", loginData);
        if (data.status_code === 200) {
            localStorage.setItem('user_token', data.data.token);
            localStorage.setItem('customerUserName', data.data.customerUsername);
            localStorage.setItem('customerUserTaxId', data.data.customerUserTaxId);
            localStorage.setItem('custerUserTaxIdADM', data.data.customerUserTaxId);
            armazenaEmailUsuarioLogadoPeloTaxId(data.data.customerUserTaxId)
            return true;
        }
        return false;
    }
    catch {
        return false;
    }
}

async function validateJWT() {
    const jwtValidationData = {
        secret_key: process.env.REACT_APP_API_EZCALE_SECRET_KEY,
        action: 'validatesession'
    }
    
    try {
        const { data } = await axios.post(process.env.REACT_APP_API_EZCALE + "/login.php",
            jwtValidationData,
            getBearerTokenConfig());

        return data.status_code === 200 ? true : false;
    }
    catch {
        return false;
    }
}

const getBearerTokenConfig = () => {

    const user_token = localStorage.getItem('user_token');
    let config = {
        headers: { Authorization: `Bearer ${user_token}` }
    };

    return config;
}

export {
    login,
    validateJWT,
    getBearerTokenConfig
}