import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getColorProfile } from '../../../../utils/appColors';
import { Button, FormControl, MenuItem, Select, TextField, Typography, InputLabel, Box } from '@mui/material';
import Loader from '../../../../components/Loader/Loader';
import handleCriaCobrancasDeAcordo from './AcordoService';
import { datasParaTextoAgrupadoPorAno } from '../../../../utils/dateFunctions';

const AcordoModal = ({ open, handleClose, cobranca }) => {
  const [honorarios, setHonorarios] = useState(false);
  const [parcela, setParcela] = useState(false);
  const [valorParcela, setValorParcela] = useState('');
  const [vencimento, setVencimento] = useState('');
  const [numeroParcelas, setNumeroParcelas] = useState(1);
  const [parcelasForm, setParcelasForm] = useState([]);
  const [total, setTotal] = useState(0);
  const [custoCobranca, setCustoCobranca] = useState(15);
  const [percentualHonorarios, setPercentualHonorarios] = useState(20);
  const [acordoData, setAcordoData] = useState();
  const [nomeCarteira, setNomeCarteira] = useState('');
  const [descricaoCarteira, setDescricaoCarteira] = useState('');
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [descricao, setDescricao] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alerta, setAlerta] = useState({}); //Objeto Alerta
  const [urlLogoSecundario, setUrlLogoSecundario] = useState('');
  const [urlFaleConosco, setUrlFaleConosco] = useState('');

  const {
    corPrincipalEscurecida,
    corSecundaria,
    corSecundariaEscurecida,
    corSecundariaMaisClara } = getColorProfile();

  useEffect(() => {
    if (cobranca !== null && cobranca !== undefined) {
      //Transforma dados recebidos da Cobrança
      cobranca['logradouro_devedor'] = cobranca.buyerlogr;
      cobranca['cep_devedor'] = cobranca.buyerpostal;
      cobranca['cidade_devedor'] = cobranca.buyercity;
      cobranca['estado_devedor'] = cobranca.buyerstate;
      cobranca['numero_devedor'] = cobranca.buyernum;
      cobranca['bairro_devedor'] = cobranca.buyerneigh;
      const urlLogoSecundarioTmp = cobranca?.custom_fields?.url_logo_secundario ? cobranca.custom_fields.url_logo_secundario : ''
      setUrlLogoSecundario(urlLogoSecundarioTmp);
      const urlFaleConoscoTmp = cobranca?.custom_fields?.url_faleconosco ? cobranca.custom_fields.url_faleconosco : ''
      setUrlFaleConosco(urlFaleConoscoTmp);
      const nomeCarteiraTmp = 'Acordo - ' + cobranca.nome_credor + ' - ' + cobranca?.custom_fields?.nome_condominio + ' - ' + cobranca?.custom_fields?.unidade_devedora
      setNomeCarteira(nomeCarteiraTmp);
      setDescricaoCarteira(nomeCarteiraTmp);
      setDescricao(nomeCarteiraTmp);
      setAcordoData(cobranca);
    }
  }, [cobranca]);


  //Valida o botão de Criação
  useEffect(() => {
    if (nomeCarteira === "" || descricaoCarteira === "" ||
      parcelasForm.length === 0 ||
      acordoData.cpf_cnpj_adm === "" ||
      acordoData.cpf_cnpj_credor === "" ||
      acordoData.cpf_cnpj_devedor === "" ||
      acordoData.nome_devedor === "" ||
      acordoData.cep_devedor === "" ||
      acordoData.logradouro_devedor === "" ||
      acordoData.email1 === "" ||
      acordoData.telefone1 === ""
    ) {
      setIsCreateDisabled(true);
    }
    else {
      setIsCreateDisabled(false);
    }
  }, [cobranca, nomeCarteira, descricaoCarteira, parcelasForm, acordoData])

  const handleCriarParcelaHonorarios = () => {
    setHonorarios(true);
    setParcela(false);
    const descricaoHonorarios = 'Acordo - Honorários - ' + cobranca?.custom_fields?.nome_condominio + ' - ' + cobranca?.custom_fields?.unidade_devedora;
    setDescricao(descricaoHonorarios);
  };

  const handleCriarParcela = () => {
    setHonorarios(false);
    setParcela(true);
    const dataPorExtenso = cobranca?.custom_fields?.observacoes1 ? datasParaTextoAgrupadoPorAno(cobranca?.custom_fields?.observacoes1) : '';
    const descricaoParcelas = 'Acordo - Referente as cotas condominiais de ' + dataPorExtenso + ' - ' + cobranca?.custom_fields?.nome_condominio + ' - ' + cobranca?.custom_fields?.unidade_devedora;
    setDescricao(descricaoParcelas);
  };

  const adicionarParcela = () => {
    if (valorParcela && vencimento) {
      const novasParcelas = [];
      const vencimentoDate = new Date(vencimento);

      if (honorarios) {
        // Adicionar parcela de honorários
        novasParcelas.push({
          descricao,
          tipo: 'Honorários',
          valor: valorParcela,
          vencimento: vencimento,
        });
      } else if (parcela && numeroParcelas) {
        // Adicionar várias parcelas
        for (let i = 0; i < numeroParcelas; i++) {
          const newVencimento = new Date(vencimentoDate);
          newVencimento.setMonth(newVencimento.getMonth() + i);

          novasParcelas.push({
            descricao,
            tipo: 'Normal',
            valor: valorParcela,
            vencimento: newVencimento.toISOString().split('T')[0],
            honorarios: percentualHonorarios,
            custoCobranca,
          });
        }
      }

      setParcelasForm([...parcelasForm, ...novasParcelas]);
      atualizarTotal([...parcelasForm, ...novasParcelas]);

      // Limpar campos
      setValorParcela('');
      setVencimento('');
      setNumeroParcelas(1);
    }
  };

  const removerParcela = (index) => {
    const novasParcelas = parcelasForm.filter((_, i) => i !== index);
    setParcelasForm(novasParcelas);
    atualizarTotal(novasParcelas);
  };

  const atualizarTotal = (parcelas) => {
    const soma = parcelas.reduce((acc, curr) => acc + parseFloat(curr.valor), 0);
    setTotal(soma);
  };

  const formatarData = (dataISO) => {
    const [ano, mes, dia] = dataISO.split('-');
    return `${dia}/${mes}/${ano}`;
  };

  const handleAcordoData = (property, value) => {
    const novoAcordoData = { ...acordoData }
    novoAcordoData[property] = value;
    setAcordoData(novoAcordoData);
  }

  // Função para aplicar a máscara de telefone no formato brasileiro
  const handleTelefoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    if (input.length > 11) input = input.slice(0, 11); // Limita o número de dígitos a 11 (padrão celular)

    // Aplica a máscara conforme o comprimento do input
    if (input.length > 2) {
      input = `(${input.slice(0, 2)}) ${input.slice(2, 7)}-${input.slice(7)}`;
    } else if (input.length > 0) {
      input = `(${input}`; // Inicia o código de área
    }

    setUrlFaleConosco(input); // Limita o input ao tamanho máximo da máscara
  };


  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${open ? '' : 'hidden'}`}>
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-5 mx-3 overflow-auto max-h-[90vh]">
        <Typography id="modal-title" variant="h5" component="h5" sx={{ color: corPrincipalEscurecida }} >
          Criação de Acordo
        </Typography>
        <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-3" />

        <TextField
          label="Nome da Carteira *"
          fullWidth
          value={nomeCarteira}
          onChange={(e) => setNomeCarteira(e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Descrição da Carteira *"
          fullWidth
          value={descricaoCarteira}
          onChange={(e) => setDescricaoCarteira(e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="CNPJ ADM da Cobrança *"
          fullWidth
          value={acordoData?.cpf_cnpj_adm || ''}
          onChange={(e) => handleAcordoData('cpf_cnpj_adm', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Nome Credor *"
          fullWidth
          value={acordoData?.nome_credor || ''}
          onChange={(e) => handleAcordoData('nome_credor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="CPF/CNPJ Credor *"
          fullWidth
          value={acordoData?.cpf_cnpj_credor || ''}
          onChange={(e) => handleAcordoData('cpf_cnpj_credor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Nome Devedor *"
          fullWidth
          value={acordoData?.nome_devedor || ''}
          onChange={(e) => handleAcordoData('nome_devedor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="CPF/CNPJ Devedor *"
          fullWidth
          value={acordoData?.cpf_cnpj_devedor || ''}
          onChange={(e) => handleAcordoData('cpf_cnpj_devedor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="E-mail *"
          fullWidth
          value={acordoData?.email1 || ''}
          onChange={(e) => handleAcordoData('email1', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Telefone *"
          fullWidth
          value={acordoData?.telefone1 || ''}
          onChange={(e) => handleAcordoData('telefone1', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="CEP Devedor *"
          fullWidth
          value={acordoData?.cep_devedor || ''}
          onChange={(e) => handleAcordoData('cep_devedor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Logradouro Devedor *"
          fullWidth
          value={acordoData?.logradouro_devedor || ''}
          onChange={(e) => handleAcordoData('logradouro_devedor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Numero Devedor *"
          fullWidth
          value={acordoData?.numero_devedor || ''}
          onChange={(e) => handleAcordoData('numero_devedor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Bairro Devedor *"
          fullWidth
          value={acordoData?.bairro_devedor || ''}
          onChange={(e) => handleAcordoData('bairro_devedor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Cidade Devedor *"
          fullWidth
          value={acordoData?.cidade_devedor || ''}
          onChange={(e) => handleAcordoData('cidade_devedor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Estado Devedor *"
          fullWidth
          value={acordoData?.estado_devedor || ''}
          onChange={(e) => handleAcordoData('estado_devedor', e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="URL Logo Secundária"
          fullWidth
          type="text"
          value={urlLogoSecundario}
          onChange={(e) => setUrlLogoSecundario(e.target.value)}
          sx={{ mb: 1.5 }}
        />

        <TextField
          label="Telefone Fale Conosco"
          fullWidth
          type="text"
          value={urlFaleConosco}
          onChange={handleTelefoneChange}
          sx={{ mb: 1.5 }}
        />

        <hr />
        <div className="space-y-4">
          <div className="flex flex-column space-x-4">
            <Button
              className="text-white rounded-lg w-full"
              sx={{
                background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                color: "white",
                borderRadius: "6px"
              }}
              onClick={handleCriarParcelaHonorarios}
            >
              Parcela de Honorários
            </Button>
            <Button
              className="text-white rounded-lg w-full"
              sx={{
                background: `linear-gradient(to right, ${corSecundariaEscurecida}, ${corSecundariaMaisClara})`,
                color: "white",
                borderRadius: "6px"
              }}
              onClick={handleCriarParcela}
            >
              Configurar Parcelas
            </Button>
          </div>

          {honorarios && (
            <div className="space-y-2">
              <Typography id="modal-title" variant="h6" component="h6" sx={{ color: corSecundaria }} >
                Criação de Parcela 100% de Honorários
              </Typography>
              <hr className="mb-4" />
              <TextField
                type="number"
                label="Valor da Parcela"
                value={valorParcela}
                onChange={(e) => setValorParcela(e.target.value)}
                className="border rounded-lg w-full p-2"
                sx={{ paddingBottom: '7px', paddingTop: '4px' }}
              />
              <TextField
                label="Vencimento"
                type="date"
                value={vencimento}
                onChange={(e) => setVencimento(e.target.value)}
                sx={{ width: '100%', color: corPrincipalEscurecida }}
                slotProps={{ inputLabel: { shrink: true } }}
              />
              <TextField
                label="Descrição da Cobrança *"
                fullWidth
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                sx={{ mb: 1.5 }}
              />

              <Button
                className="bg-gradient-to-r from-green-500 to-green-400 text-white rounded-lg py-2 px-4"
                onClick={adicionarParcela} // Botão Adicionar
                sx={{ color: "white" }}
              >
                Adicionar
              </Button>
            </div>
          )}

          {parcela && (
            <div className="space-y-2">
              <TextField
                type="number"
                label="Valor da Parcela"
                value={valorParcela}
                onChange={(e) => setValorParcela(e.target.value)}
                className="border rounded-lg w-full p-2"
              />
              <TextField
                type="date"
                label="Vencimento"
                value={vencimento}
                onChange={(e) => setVencimento(e.target.value)}
                className="border rounded-lg w-full p-2"
                slotProps={{ inputLabel: { shrink: true } }}
              />
              <FormControl fullWidth>
                <InputLabel id="simple-select-label">Repetir em quantas Parcelas?</InputLabel>
                <Select
                  label="Repetir em quantas Parcelas?"
                  value={numeroParcelas}
                  fullWidth
                  onChange={(e) => setNumeroParcelas(e.target.value)}
                  slotProps={{ inputLabel: { shrink: true } }}
                  sx={{ height: '60px', mb: 1.5 }}
                >
                  <MenuItem value="1" key="numeroParcelas">Selecione o número de parcelas para repetir Cobrança</MenuItem>
                  {[...Array(120).keys()].map(i => (
                    <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Honorários (%)"
                type="number"
                value={percentualHonorarios}
                onChange={(e) => setPercentualHonorarios(e.target.value)}
                className="border rounded-lg w-full p-2"
              />
              <TextField
                disabled={true}
                label="Custo de Cobrança (R$)"
                type="number"
                value={custoCobranca}
                onChange={(e) => setCustoCobranca(e.target.value)}
                className="border rounded-lg w-full p-2"
              />
              <TextField
                label="Descrição da Cobrança *"
                fullWidth
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                sx={{ mb: 1.5 }}
              />
              <Button
                className="bg-gradient-to-r from-green-500 to-green-400 text-white rounded-lg py-2 px-4"
                onClick={adicionarParcela} // Botão Adicionar
                sx={{ color: "white" }}
              >
                Adicionar Parcela(s)
              </Button>
            </div>
          )}

          {parcelasForm.length > 0 && (
            <div className="space-y-2">
              <h3 style={{ color: corPrincipalEscurecida }} className="font-bold">Parcelas à Serem Criadas</h3>
              {parcelasForm
                .sort((a, b) => new Date(a.vencimento) - new Date(b.vencimento)) // Ordena as parcelas pela data de vencimento
                .map((parcela, index) => (
                  <div key={index} className="flex justify-between items-center border p-2 rounded-lg">
                    <div>
                      <p style={{ color: corPrincipalEscurecida }}><strong>{parcela.tipo === 'Honorários' ? <span className="text-red-500">Parcela de Honorários</span> : 'Parcela:'} R$ {parcela.valor}</strong></p>
                      <p style={{ color: corPrincipalEscurecida }}><strong>Vencimento:</strong> {formatarData(parcela.vencimento)}</p> {/* Formata a data */}
                      <p style={{ color: corPrincipalEscurecida }}><strong>Descrição:</strong> {parcela.descricao}</p>
                      {parcela.tipo === 'Normal' && (
                        <>
                          <p style={{ color: corPrincipalEscurecida }}><strong>Honorários:</strong> {parcela.honorarios}%</p>
                          <p style={{ color: corPrincipalEscurecida }}><strong>Custo de Cobrança:</strong> R$ {parcela.custoCobranca}</p>
                        </>
                      )}
                    </div>
                    <Button onClick={() => removerParcela(index)}>
                      <DeleteForeverIcon className="text-red-500" />
                    </Button>
                  </div>
                ))}
            </div>
          )}

          {parcelasForm.length > 0 && (
            <div className="mt-4">
              <p style={{ color: corPrincipalEscurecida }} className="font-bold">Soma Total: R$ {total.toFixed(2)}</p>
              <div style={{ borderBottom: `1.8px solid ${corPrincipalEscurecida}` }} />
              <p style={{ color: corSecundariaEscurecida }} className="font-bold">Valor Original: R$ {cobranca?.valor_total_cobranca}</p>
            </div>
          )}
        </div>

        <hr className='mt-2 mb-2' />
        {/* Mostra Alerta de Resultado do Processamento */}
        {Object.keys(alerta).length > 0 && (
          <Alert className="signin-area" severity={alerta.type}>
            <AlertTitle>{alerta.titulo}</AlertTitle>
            {alerta.descricao}
          </Alert>)}

        {/* Botões de Ação */}
        {!isLoading ? (
          <div className="flex justify-end space-x-4 mt-5">
            <Button
              sx={{
                background: "linear-gradient(to right, #f87171, #ef4444)", // Botão cancelar vermelho
                color: "white",
                borderRadius: "6px", // Border radius de 6px
                height: "35px",
                mr: 1,
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              disabled={isCreateDisabled}
              onClick={() => handleCriaCobrancasDeAcordo(nomeCarteira, descricaoCarteira, acordoData,
                parcelasForm, setIsLoading, setAlerta, urlLogoSecundario, urlFaleConosco)}
              sx={{
                background: "linear-gradient(to right, #66bb6a, #43a047)", // Botão criar
                color: "white",
                borderRadius: "6px", // Border radius de 6px
                height: "35px",
              }}
            >
              Criar
            </Button>
          </div>)
          :
          (
            <Box className="w-full mt-5" sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Loader isLoading={isLoading} />
            </Box>
          )}

      </div>
    </div>
  );
};

export default AcordoModal;
