import { criaCobranca, darBaixaEmCobranca } from '../../../../api/ezcale/cobrancas';

async function handleCriaSegundaVia(
    segundaViaData,
    setIsLoading,
    setAlerta
) {
    let response = false;
    setIsLoading(true);
    const description = segundaViaData.description ? segundaViaData.description : segundaViaData?.custom_fields?.nome_condominio + ' | ' + segundaViaData?.custom_fields?.unidade_devedora + ' - 2ª via';
    const segundaViaPayload = {
        vencimento: segundaViaData.vencimento,
        name: segundaViaData.invoiceName + '- 2ª via',
        description: description,
        cpf_cnpj_adm: segundaViaData.cpf_cnpj_adm,
        nome_credor: segundaViaData.nome_credor,
        cpf_cnpj_credor: segundaViaData.cpf_cnpj_credor,
        nome_devedor: segundaViaData.nome_devedor,
        cpf_cnpj_devedor: segundaViaData.cpf_cnpj_devedor,
        logradouro_devedor: segundaViaData.logradouro_devedor,
        numero_devedor: segundaViaData.numero_devedor,
        bairro_devedor: segundaViaData.bairro_devedor,
        cidade_devedor: segundaViaData.cidade_devedor,
        estado_devedor: segundaViaData.estado_devedor,
        cep_devedor: segundaViaData.cep_devedor,
        telefone1: segundaViaData?.telefone1?.includes('não informado') ? '' : segundaViaData.telefone1,
        email1: segundaViaData?.email1?.includes('não informado') ? '' : segundaViaData.email1,
        telefone2: segundaViaData?.telefone2?.includes('não informado') ? '' : segundaViaData.telefone2,
        email2: segundaViaData?.email2?.includes('não informado') ? '' : segundaViaData.email2,
        url_logo_secundario: segundaViaData?.custom_fields?.url_logo_secundario,
        url_faleconosco: segundaViaData?.custom_fields?.url_faleconosco,
        nome_condominio: segundaViaData?.custom_fields?.nome_condominio ?? '',
        unidade_devedora: segundaViaData?.custom_fields?.unidade_devedora ?? '',
        observacoes1: segundaViaData.observacoes1,
        valor_original: segundaViaData?.valor_original ?? 0,
        valor_juros: segundaViaData?.valor_juros ?? 0,
        valor_multa: segundaViaData?.valor_multa ?? 0,
        custo_cobranca: segundaViaData.custo_cobranca,
        custom_honorarios_fee: Number(segundaViaData.custom_honorarios_fee)
    }
    if(segundaViaData?.correcao_monetaria_igpm && segundaViaData?.correcao_monetaria_igpm > 0) {
        segundaViaPayload['correcao_monetaria_igpm'] = segundaViaData?.correcao_monetaria_igpm
    }
    if(segundaViaData?.custom_fields?.vencimento_original) {
        segundaViaPayload['vencimento_original'] = segundaViaData?.custom_fields?.vencimento_original
    }
    if (segundaViaData?.valor_total_honorarios > 0) {
        segundaViaPayload['valor_total_cobranca'] = 0;
        segundaViaPayload['valor_total_honorarios'] = segundaViaData.valor_total_honorarios;
    }
    else {
        const totalPreHonorarios = Number(segundaViaPayload['valor_original']) +
            Number(segundaViaPayload['valor_juros'] ?? 0) +
            Number(segundaViaPayload['valor_multa'] ?? 0 ) +
            Number(segundaViaPayload['correcao_monetaria_igpm'] ?? 0);
        segundaViaPayload['valor_total_cobranca'] = totalPreHonorarios;
    }

    if (segundaViaData.invoiceTypeName == 'EB004') {
        segundaViaPayload['tipo_cobranca'] = "cobranca_extrajudicial";
    }
    else {
        segundaViaPayload['tipo_cobranca'] = "cobranca_amigavel";
    }

    const numeroCobrancaOriginal = segundaViaData.hashInvoiceId;
    const numeroCarteira = segundaViaData.hashInvoiceGroupId;

    response = await criaCobranca(segundaViaPayload, numeroCarteira);

    if (response) { //Caso a Segunda Via tenham sido criadas
        const motivo = "Cobrança Baixada em Função de Segunda via Gerada";
        await darBaixaEmCobranca([numeroCobrancaOriginal], motivo);
        setAlerta({
            type: 'success',
            titulo: 'A Segunda Via foi Gerada com Sucesso!',
            descricao: ''
        });
        setTimeout(() => {
            window.location.reload();
        }, 500)
    }
    else {
        setAlerta({
            type: 'error',
            titulo: 'As Cobranças de Acordo não foram Criadas',
            descricao: 'Um Erro aconteceu, tente novamente'
        });
        setIsLoading(false);
    }
}

export default handleCriaSegundaVia;