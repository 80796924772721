import axios from 'axios';

const buscarCondominioPorNome = async (nomeCondominio, credor_tax_id) => {
    try {
        const encodedName = encodeURIComponent(nomeCondominio); // Codifica espaços e caracteres especiais
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + `/condominio/buscaPorNome?nome=${encodedName}&tax_id=${credor_tax_id}`;
        const response = await axios.get(endpoint);
        return response.data; // Retorna os dados do condomínio
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.error('Condomínio não encontrado.');
        } else {
            console.error('Erro ao buscar condomínio:', error);
        }
        throw error;
    }
};

const atualizarCondominio = async (dados) => {
    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/condominio/update';
        const response = await axios.post(endpoint, dados);
        return response.data; // Retorna a resposta do servidor
    } catch (error) {
        console.error('Erro ao atualizar condomínio:', error);
        throw error;
    }
};

const buscarCondominioPorId = async (id_condominio) => {
    try {
        const encodedId = encodeURIComponent(id_condominio); // Codifica espaços e caracteres especiais
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + `/condominio/buscaPorId?id=${encodedId}`;
        const response = await axios.get(endpoint);
        return response.data; // Retorna os dados do condomínio
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.error('Condomínio não encontrado.');
        } else {
            console.error('Erro ao buscar condomínio:', error);
        }
        throw error;
    }
};

export {
    buscarCondominioPorNome,
    atualizarCondominio,
    buscarCondominioPorId
}
