import { RouterProvider } from 'react-router-dom';
import Router from './router/Router';
import { buildMarcaApp } from './api/ezcale/marca';
import { useEffect, useState } from 'react';
import LogoWithCircularProgress from './components/Loader/CircularLoaderWithCenterLogo';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function marcaEndpointApi() {
      try {
        await buildMarcaApp();
      } catch (error) {
        console.error("Erro ao construir a marca: ", error);
      } finally {
        // Após a função ser executada, o carregamento termina
        setIsLoading(false);
      }
    } //Pega informações da Marca

    marcaEndpointApi();
  }, []);

  if (isLoading || !localStorage.getItem('portalDomainLogo')) {
    return (<div className="w-full flex items-center justify-center overflow-auto h-screen">
      <div className="w-[95%] h-[80vh] flex flex-col space-y-5 justify-start items-center">
        <LogoWithCircularProgress />
      </div>
    </div>)
  }

  // Renderizar o RouterProvider se tudo estiver carregado
  return <RouterProvider router={Router} />;
}

export default App;
