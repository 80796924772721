import axios from 'axios';
import {criaEntradaNoHistoricoDaCobranca} from '../ezcale/historico';

async function buscaDadosAcordoPorCarteira(hashGroupId) {

    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/acordo/buscaPorCarteira/' + hashGroupId;
        const { data } = await axios.get(endpoint);
        if (data?.acordo?.id) {
            return data;
        }
        return {};
    }
    catch {
        return {};
    }
}

async function atualizaAcordo(acordoData, hashInvoiceOriginalId) {

    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/acordo/editar';
        const { data } = await axios.post(endpoint, acordoData);
        if (data?.success) {
            const descricaoDoEvento = 'O acordo recebeu uma atualização para: ' + JSON.stringify(acordoData);
            await criaEntradaNoHistoricoDaCobranca(hashInvoiceOriginalId, descricaoDoEvento);
            return data.data;
        }
        return {};
    }
    catch {
        return {};
    }
}

async function listarAcordos(dateFrom, dateTo, customersTaxIdArray = []) {
    
    let arrayDeCustomerTaxId = ''; //Array no formato Query Parameter
    customersTaxIdArray.forEach(element => {
        arrayDeCustomerTaxId += '&credores[]=' + element;
    });
    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/acordo/listar?dateFrom=' + dateFrom + '&dateTo=' + dateTo + arrayDeCustomerTaxId;
        const { data } = await axios.get(endpoint);
        if (data.length > 0) {
            return data;
        }
        return [];
    }
    catch {
        return [];
    }
}

async function geraCodigoAutenticacao(acordoId) {

    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/acordo/geraCodigoAutenticacao';
        const { data } = await axios.post(endpoint+'/' + acordoId);
        if (data?.success) {
            return data.data;
        }
        return 'asjidh9a8sdhasdjihas0dhas8d';
    }
    catch {
        return 'asjidh9a8sdhasdjihas0dhas8d';
    }
}


async function buscaCobrancaPorHashCobrancaOriginal(hash_cobranca_original) {
    
    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/acordo/buscaAcordo/'+hash_cobranca_original;
        const { data } = await axios.get(endpoint);
        if (data !== null) {
            return data;
        }
        return false;
    }
    catch {
        return false;
    }
}

export {
    buscaDadosAcordoPorCarteira,
    atualizaAcordo,
    listarAcordos,
    geraCodigoAutenticacao,
    buscaCobrancaPorHashCobrancaOriginal
}