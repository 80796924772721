import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
  Button,
  Menu,
  MenuItem,
  Divider,
  TextField,
  Box
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Ícone de voltar
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TaskIcon from '@mui/icons-material/Task';
// import OpenIcon from "@mui/icons-material/FolderOpen";
// import HistoryIcon from "@mui/icons-material/History";
// import ReceiptIcon from "@mui/icons-material/Receipt";
// import DeleteIcon from "@mui/icons-material/Delete";
import { getColorProfile } from '../../utils/appColors';
import AcordoModal from "./Modal/CriarAcordo/AcordoModal";
import { buscaCarteiras } from "../../api/ezcale/carteiras";
import { buscaDadosAcordoPorCarteira } from '../../api/backend-csi/acordo';
import { buscaCobrancas } from '../../api/ezcale/cobrancas';
import { getInvoiceStatusButton } from '../../utils/statusButton';
import CircularLoaderWithCenterLogo from '../../components/Loader/CircularLoaderWithCenterLogo';
import { buscaDetalhesDaCobranca } from './CobrancaService';
import SegundaViaModal from "./Modal/SegundaVia/SegundaViaModal";
import { formataDataHoraMySQLParaDataBrasileira } from '../../utils/dateFunctions';
import { formatDate } from '../../utils/dateFunctions';
import DateRangePicker from "../../utils/DateRangePicker";
import DarBaixarModal from "./Modal/DarBaixa/DarBaixarModal";
import CobrancasMenu from "./CobrancasMenu";
import EnviarPropostaDeAcordoModal from "./Modal/EnviarPropostaDeAcordo/EnviarPropostaDeAcordoModal";

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

export default function Cobrancas() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCobranca, setSelectedCobranca] = useState(null); // Variável para armazenar a cobrança selecionada
  const [acordoModalOpen, setAcordoModalOpen] = useState(false);
  const [segundaViaModalOpen, setSegundaViaModalOpen] = useState(false);
  const [darBaixaModalOpen, setDarBaixaModalOpen] = useState(false);
  const [enviarPropostaDeAcordoModal, setEnviarPropostaDeAcordoModal] = useState(false);
  const [cobrancas, setCobrancas] = useState([]);
  const [todasCobrancas, setTodasCobrancas] = useState([]); //Necessario para Armazenar as cobrancas sem filtro
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchTerm, setSearchTerm] = useState('');  // Estado para o termo de busca
  const [nomeCarteira, setNomeCarteira] = useState('');
  //Utilizado no DatePicker
  const [dateto, setDateto] = useState(formatDate(new Date()));
  const [datefrom, setDatefrom] = useState(formatDate(new Date()));
  const [selection, setSelection] = useState('Hoje');
  const [acordoDetalhes, setAcordoDetalhes] = useState({});
  const [propostaEnviada, setPropostaEnviada] = useState(false);
  const [showEnviarPropostaDeAcordoModalButton, setShowEnviarPropostaDeAcordoModalButton] = useState(false);
  const { hashId } = useParams(); // Pega o parâmetro 'hashId' da carteira
  const navigate = useNavigate(); // Hook para navegação
  const { corPrincipal,
    corPrincipalEscurecida,
    corSecundaria,
    corSecundariaEscurecida,
    corSecundariaMaisClara } = getColorProfile();

  useEffect(() => {
    buscaCobrancasData();
  }, [datefrom, dateto]);

  useEffect(() => {
    const checkTemAcordo = async () => {
      if (nomeCarteira.includes('Acordo')) {
        const acordoDetails = await buscaDadosAcordoPorCarteira(hashId);
        if (acordoDetails?.acordo?.id) {
          setShowEnviarPropostaDeAcordoModalButton(true);
          if (acordoDetails?.acordo?.data_envio != null) {
            setPropostaEnviada(true);
            setAcordoDetalhes(acordoDetails);
          }
        }
      }
    }
    
    checkTemAcordo();

  }, [nomeCarteira]);

  const buscaCobrancasData = async () => {
    let cobrancas = [];
    setIsLoading(true);
    if (hashId !== 'all' && nomeCarteira == '') {
      cobrancas = await buscaCobrancas(hashId);
      //Pega o id da Carteira
      const hashGroupId = cobrancas[0]?.hashInvoiceGroupId;
      const carteiraData = { //Usado para Pegar o nome da Carteira
        dateto: formatDate(new Date()),
        datefrom: formatDate(new Date(new Date().setDate(new Date().getDate() - 600))),
        hashId: hashGroupId,
      };
      const fetchedCarteira = await buscaCarteiras(carteiraData);
      if (fetchedCarteira[0]?.name) {
        setNomeCarteira(fetchedCarteira[0]?.name)
      }
    }
    else {
      cobrancas = await buscaCobrancas(hashId, datefrom, dateto);
    }
    setCobrancas(cobrancas);
    setTodasCobrancas(cobrancas);
    setIsLoading(false);
  }



  const handleDateChange = async (range) => {
    setDatefrom(range.datefrom);
    setDateto(range.dateto);
  }

  const handleDelete = (id) => {
    if (window.confirm("Tem certeza que deseja excluir esta cobrança?")) {
      console.log("Deletando cobrança com ID:", id);
    }
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);

    const sortedData = [...cobrancas].sort((a, b) => {
      const valA = a[column].trim();
      const valB = b[column].trim();

      // Checa se os valores são numéricos
      const isNumeric = !isNaN(valA) && !isNaN(valB);

      // Checa se os valores são datas
      const isDate = !isNaN(Date.parse(valA)) && !isNaN(Date.parse(valB));

      let comparison = 0;

      if (isNumeric) {
        // Comparação para números
        comparison = Number(valA) - Number(valB);
      } else if (isDate) {
        // Comparação para datas
        comparison = new Date(valA) - new Date(valB);
      } else {
        // Comparação para strings com números embutidos
        const compareStringsWithNumbers = (str1, str2) => {
          const regex = /(\d+|\D+)/g; // Regexp para dividir a string em números e letras
          const parts1 = str1.match(regex);
          const parts2 = str2.match(regex);

          // Compara as partes separadas (números como inteiros e texto como strings)
          for (let i = 0; i < Math.min(parts1.length, parts2.length); i++) {
            const part1 = parts1[i];
            const part2 = parts2[i];

            const num1 = parseInt(part1, 10);
            const num2 = parseInt(part2, 10);

            // Se ambos são números, compara numericamente
            if (!isNaN(num1) && !isNaN(num2)) {
              if (num1 !== num2) {
                return num1 - num2;
              }
            } else {
              // Caso contrário, compara lexicograficamente
              const textComparison = part1.localeCompare(part2);
              if (textComparison !== 0) {
                return textComparison;
              }
            }
          }

          // Se todos os segmentos forem iguais, compara pelo comprimento da string
          return parts1.length - parts2.length;
        };

        comparison = compareStringsWithNumbers(valA, valB);
      }

      return isAsc ? comparison : -comparison;
    });

    setCobrancas(sortedData);
  };

  const handleDropdownClick = (event, cobranca) => {
    setSelectedCobranca(cobranca);
    setAnchorEl(event.currentTarget);
    buscaDetalhesDaCobranca(cobranca.hashInvoiceId, setSelectedCobranca);
  };

  // Função de filtro
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const cobrancasParafiltro = [...todasCobrancas];
    const filtered = cobrancasParafiltro.filter((item) =>
      item.nome_devedor?.toLowerCase().includes(term) ||
      item.descriptor?.toLowerCase().includes(term) ||
      item.email1?.toLowerCase().includes(term)
    );

    setCobrancas(filtered);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedCobranca(null); // Limpa a cobrança selecionada
  };

  const getValorFormatado = (valor) => {
    return "R$ " + valor.replace('.', ',');
  }

  const getVencimentoFormatado = (vencimento) => {
    const [date] = vencimento.split(" ");
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  return (
    isLoading ? (<div className="w-full h-full flex items-center justify-center">
      <CircularLoaderWithCenterLogo />
    </div>) : (
      <ThemeProvider theme={theme}>
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-center items-center" >
            <div className="w-full flex flex-col justify-start">
              <Typography variant="h4" component="h4" sx={{ color: corPrincipalEscurecida }}>
                Cobranças
              </Typography>
              <Typography variant="h5" component="h5" sx={{ color: corPrincipal }}>
                Carteira - {nomeCarteira}
              </Typography>
            </div>
            {/* <Button 
              variant="contained"
              sx={{
                background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                color: "white",
                borderRadius: "6px",
                padding: "0px 20px",
                fontSize: "1rem",
                height: "35px"
              }}
              onClick={() => { }}
            >
              Criar Cobrança
            </Button> */}
          </div>
          <div className="w-full bg-white p-4 shadow-lg">
            {/* Separador acima */}
            <Divider className="mb-2" sx={{ bgcolor: corPrincipalEscurecida }} />
            <Box className="flex flex-col md:flex-row md:justify-between items-center w-full space-y-3 md:space-y-1">
              <Button
                className="flex-shrink-0 md:mb-0 mt-2"
                onClick={() => navigate(-1)}
                sx={{ color: corPrincipalEscurecida, borderColor: corPrincipalEscurecida, mb: '-10px' }}
                variant="outlined"
                startIcon={<ArrowBackIcon />}>Voltar</Button>
              {/* Campo de busca estilizado */}
              {
                showEnviarPropostaDeAcordoModalButton && !propostaEnviada ? (
                  <Button
                    className="flex-shrink-0 md:mb-0 mt-2"
                    onClick={() => setEnviarPropostaDeAcordoModal(true)}
                    sx={{ color: corPrincipalEscurecida, borderColor: corPrincipalEscurecida, mb: '-10px' }}
                    variant="outlined"
                    startIcon={<TaskIcon />}>Enviar Proposta de Acordo</Button>
                ) : showEnviarPropostaDeAcordoModalButton && propostaEnviada && (
                  <Button

                    className="flex-shrink-0 md:mb-0 mt-2"
                    onClick={() => setEnviarPropostaDeAcordoModal(true)}
                    sx={{ color: corPrincipalEscurecida, borderColor: corPrincipalEscurecida, mb: '-10px' }}
                    variant="outlined"
                    startIcon={<TaskIcon />}>Proposta de Acordo Enviada ({formataDataHoraMySQLParaDataBrasileira(acordoDetalhes?.acordo?.data_envio, 3)}) </Button>
                )
              }
              {hashId == 'all' && (<DateRangePicker
                onDateChange={(range) => handleDateChange(range)}
                dateTo={dateto}
                dateFrom={datefrom}
                setDateTo={setDateto}
                setDateFrom={setDatefrom}
                selection={selection}
                setSelection={setSelection} />)}
              <TextField
                className="flex-shrink-0 shadow-sm max-w-xs pt-[500px]"
                variant="outlined"
                placeholder="Procurar"
                value={searchTerm}
                onChange={handleSearch}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '40px', // Altura personalizada
                    width: '150px', // Largura mínima
                    backgroundColor: '#f7f7f7', // Cor de fundo customizada
                    borderRadius: '8px', // Borda arredondada
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: corPrincipalEscurecida, // Cor personalizada escurecida no hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: corPrincipal, // Cor personalizada quando o campo está em foco
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: corPrincipal, // Cor padrão
                  },
                }}
              />

              {/* Botão de Configuração com ícone
              <IconButton className="ml-4 hover:bg-gray-200">
                <SettingsIcon style={{ fontSize: 30, color: '#616161' }} />
              </IconButton> */}
            </Box>
          </div>
          {/* Mobile version: Display as Cards */}
          <div className="lg:hidden w-full space-y-3 mt-4" >
            {
              cobrancas.map((cobranca, index) => {
                const backgroundColor = index % 2 === 0 ? '#FFF9F5' : '#F5F9FF';
                return (<Card
                  key={cobranca.id}
                  sx={{
                    backgroundColor,
                    width: "100%",
                    boxShadow: 3,
                    borderRadius: 6,
                  }}
                >
                  <CardContent>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-4">
                        <Typography variant="h6" component="div" sx={{ color: corPrincipalEscurecida }}>
                          {cobranca.nome_devedor}
                        </Typography>
                      </div>
                      <div className="flex space-x-2">
                        <Button
                          variant="contained"
                          onClick={(event) => handleDropdownClick(event, cobranca)}
                          sx={{
                            background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                            borderRadius: "6px",
                            color: "white",
                          }}
                        >
                          Ações
                        </Button>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Typography color="textSecondary"><b>Valor:</b> {getValorFormatado(cobranca.valor_total_cobranca)}</Typography>
                      <Typography variant="body2"><b>Vencimento:</b> {getVencimentoFormatado(cobranca.vencimento)}</Typography>
                      <Typography variant="body2"><b>E-mail:</b> {cobranca.email1}</Typography>
                      <Typography variant="body2"><b>Celular: </b>{cobranca.telefone1}</Typography>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex justify-start">
                        {getInvoiceStatusButton(cobranca.status)}
                      </div>
                    </div>
                  </CardContent>
                </Card>
                )
              })
            }
          </div >

          {/* Desktop version: Display as Table */}
          < TableContainer component={Paper} className="hidden lg:block w-[80%] shadow-lg p-2" sx={{ borderRadius: "6px" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: corPrincipal, color: 'white' }}>
                  <TableCell
                    className="cursor-pointer"
                    align="center"
                    onClick={() => handleSort("createdAt")}
                    sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                    <b>Criado Em</b> {sortColumn === "createdAt" ?
                      (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                  </TableCell>
                  <TableCell
                    className="cursor-pointer"
                    onClick={() => handleSort("nome_devedor")}
                    align="center"
                    sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                    Nome {sortColumn === "nome_devedor" ?
                      (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                  </TableCell>
                  <TableCell
                    className="cursor-pointer"
                    onClick={() => handleSort("descriptor")}
                    align="center"
                    sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                    Descrição {sortColumn === "descriptor" ?
                      (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("status")}
                    className="cursor-pointer"
                    align="center"
                    sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                    Status {sortColumn === "status" ?
                      (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cursor-pointer"
                    sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}
                    onClick={() => handleSort("valor_total_cobranca")} >
                    Valor {sortColumn === "valor_total_cobranca" ?
                      (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cursor-pointer"
                    sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}
                    onClick={() => handleSort("vencimento")} >
                    Vencimento {sortColumn === "vencimento" ?
                      (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="cursor-pointer"
                    sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}
                    onClick={() => handleSort("email1")} >
                    E-mail {sortColumn === "email1" ?
                      (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cobrancas.map((cobranca, index) => {
                  const backgroundColor = index % 2 === 0 ? '#FFF9F5' : '#F5F9FF';
                  return (<TableRow key={cobranca.id} sx={{ backgroundColor }}>
                    <TableCell
                      align="center"
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                      {formataDataHoraMySQLParaDataBrasileira(cobranca.createdAt)}
                    </TableCell>
                    <TableCell align="center" sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>{cobranca.nome_devedor}</TableCell>
                    <TableCell align="center" sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>{cobranca.descriptor}</TableCell>
                    <TableCell align="center" sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>{getInvoiceStatusButton(cobranca.status)}</TableCell>
                    <TableCell align="center" sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>{getValorFormatado(cobranca.valor_total_cobranca)}</TableCell>
                    <TableCell align="center" sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>{getVencimentoFormatado(cobranca.vencimento)}</TableCell>
                    <TableCell align="center" sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>{cobranca.email1}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        onClick={(event) => handleDropdownClick(event, cobranca)}
                        sx={{
                          background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                          borderRadius: "6px",
                          color: "white",
                        }}
                      >
                        Ações
                      </Button>
                    </TableCell>
                  </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer >
        </div >
        {/* Modais */}
        {selectedCobranca?.hashInvoiceId && (
          <AcordoModal
            open={acordoModalOpen}
            handleClose={() => setAcordoModalOpen(false)
            }
            cobranca={selectedCobranca}
          />
        )}

        {selectedCobranca?.hashInvoiceId && (
          <SegundaViaModal
            open={segundaViaModalOpen}
            handleClose={() => setSegundaViaModalOpen(false)
            }
            cobranca={selectedCobranca}
          />
        )}

        {hashId && enviarPropostaDeAcordoModal && (
          <EnviarPropostaDeAcordoModal
            open={enviarPropostaDeAcordoModal}
            handleClose={() => setEnviarPropostaDeAcordoModal(false)}
            hashCarteiraId={hashId}
            cobrancas={cobrancas}
            setAcordoDetalhes={setAcordoDetalhes}
          />)}

        {selectedCobranca?.hashInvoiceId && (
          <DarBaixarModal
            open={darBaixaModalOpen}
            handleClose={() => setDarBaixaModalOpen(false)
            }
            cobranca={selectedCobranca}
          />
        )}
        {anchorEl !== null && (<CobrancasMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          setAcordoModalOpen={setAcordoModalOpen}
          setSegundaViaModalOpen={setSegundaViaModalOpen}
          setDarBaixaModalOpen={setDarBaixaModalOpen}
          selectedCobranca={selectedCobranca}
          setIsLoading={setIsLoading}
        />)}
      </ThemeProvider >)
  );
}
