import axios from 'axios';
import { getBearerTokenConfig } from './auth';

async function criaEntradaNoHistoricoDaCobranca(hashInvoiceId, descricaoDoEvento) {

    const endpoint = localStorage.getItem('portalDomain') + '/api/v2' ?? process.env.REACT_APP_API_EZCALE;

    const criaEventoData = {
        action: 'insertnewinvoiceevent',
        hashId: hashInvoiceId,
        eventType: "contract.test",
        eventDescription: descricaoDoEvento
    }
    try {
        const { data } = await axios.post(endpoint + "/invoices.php",
            criaEventoData,
            getBearerTokenConfig());
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return [];
    }
}

export {
    criaEntradaNoHistoricoDaCobranca
}