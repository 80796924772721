import { criaCobrancasDeAcordo } from '../../../../api/backend-csi/cobranca';
import { darBaixaEmCobranca } from '../../../../api/ezcale/cobrancas';
import { criaEntradaNoHistoricoDaCobranca } from '../../../../api/ezcale/historico';

async function handleCriaCobrancasDeAcordo(
    nomeCarteira,
    descricaoCarteira,
    acordoData,
    parcelas,
    setIsLoading,
    setAlerta,
    urlLogoSecundario,
    urlFaleConosco
) {
    let response = false;
    setIsLoading(true);

    const acordoPayload = {
        name: nomeCarteira,
        description: descricaoCarteira,
        type: 'EB004',
        cpf_cnpj_adm: acordoData.cpf_cnpj_adm,
        nome_credor: acordoData.nome_credor,
        cpf_cnpj_credor: acordoData.cpf_cnpj_credor,
        nome_devedor: acordoData.nome_devedor,
        cpf_cnpj_devedor: acordoData.cpf_cnpj_devedor,
        logradouro_devedor: acordoData.logradouro_devedor,
        numero_devedor: acordoData.numero_devedor,
        bairro_devedor: acordoData.bairro_devedor,
        cidade_devedor: acordoData.cidade_devedor,
        estado_devedor: acordoData.estado_devedor,
        cep_devedor: acordoData.cep_devedor,
        telefone1: acordoData?.telefone1?.includes('não informado') ? '' : acordoData.telefone1,
        email1: acordoData?.email1?.includes('não informado') ? '' : acordoData.email1,
        cobranca_original: acordoData.hashInvoiceId,
        email_adm: localStorage.getItem('loggedUserEmail'),
        cobrancasHonorarios: [],
        parcelas: [],
        url_logo_secundario: urlLogoSecundario,
        url_faleconosco: urlFaleConosco
    }

    parcelas.forEach(parcela => {
        if (parcela.tipo === "Honorários") {
            acordoPayload.cobrancasHonorarios.push({
                valor_total_honorarios: parcela.valor,
                custom_honorarios_fee: 0,
                custo_cobranca: parcela.custoCobranca ? parcela.custoCobranca : 15,
                valor_total_cobranca: 0,
                valor_original: 0,
                vencimento: parcela.vencimento,
                description: parcela.descricao,
                nome_condominio: acordoData?.custom_fields?.nome_condominio ?? '',
                unidade_devedora: acordoData?.custom_fields?.unidade_devedora ?? ''
            })
        }
        else {
            acordoPayload.parcelas.push({
                valor_total_cobranca: parcela.valor,
                valor_original: parcela.valor,
                vencimento: parcela.vencimento,
                description: parcela.descricao,
                custom_honorarios_fee: parcela.honorarios / 100,
                custo_cobranca: parcela.custoCobranca,
                nome_condominio: acordoData?.custom_fields?.nome_condominio ?? '',
                unidade_devedora: acordoData?.custom_fields?.unidade_devedora ?? ''
            })
        }
    });

    acordoPayload.parcelas = adicionarObservacao(acordoPayload.parcelas, acordoData.nome_devedor);
    response = await criaCobrancasDeAcordo(acordoPayload);
    await criaEntradaNoHistoricoDaCobranca(acordoData.hashInvoiceId,
        'Uma acordo foi gerado para essa fatura: Carteira de Acordo: ' + window.location.host + '/cobrancas/' + response?.hashInvoiceGroupId);
    //Dar baixa na Cobrança Original
    await darBaixaEmCobranca([acordoData.hashInvoiceId], 'Cobrança Baixa em Função de Acordo Gerado')
    if (response) {
        //Caso as Carteiras tenham sido criadas
        setAlerta({
            type: 'success',
            titulo: 'As Cobranças de Acordo foram Geradas com Sucesso!',
            descricao: ''
        });
        setTimeout(() => {
            window.location.href = '/cobrancas/' + response.hashInvoiceGroupId; //relative to domain
        }, 500)
    }
    else {
        setAlerta({
            type: 'error',
            titulo: 'As Cobranças de Acordo não foram Criadas',
            descricao: 'Um Erro aconteceu, tente novamente'
        });
    }
    setIsLoading(false);
}

function adicionarObservacao(arr, nome_devedor) {
    return arr.map((obj, index) => ({
        ...obj,
        observacoes1: `Acordo ${nome_devedor} - Parcela ${index + 1} de ${arr.length}`
    }));
}

export default handleCriaCobrancasDeAcordo;