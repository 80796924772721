import axios from 'axios';

const buscaAdministradoraPorTaxId = async (cnpj_administradora) => {
    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + `/administradoras/buscaDados/${cnpj_administradora}`;
        const response = await axios.get(endpoint);
        if (response.data?.tax_id) {
            return response.data; // Retorna os dados da administradora
        }
        return {};
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.log('Administradora não encontrada.', error);
            return {};
        }
        console.error('Erro ao buscar Administradora:', error);
        return {};
    }
};

/**
 * 
 * @param {*} cnpj_administradora 
 * @param {*} updateData = {
 * "email": "string", "nome": "string"}
 * @returns 
 */
const atualizaAdministradoraPorId = async (administradora_id, updateData) => {
    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/administradoras/atualiza/' + administradora_id;
        const response = await axios.post(endpoint, updateData);
        return response.data; // Retorna a resposta do servidor
    } catch (error) {
        console.error('Erro ao atualizar condomínio:', error);
        throw error;
    }
};


export {
    atualizaAdministradoraPorId
}
