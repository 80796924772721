import { getDetalhesDaCobranca } from '../../../../api/ezcale/cobrancas';
import { criaEntradaNoHistoricoDaCobranca } from '../../../../api/ezcale/historico';
import { buscaDadosAcordoPorCarteira, atualizaAcordo } from '../../../../api/backend-csi/acordo';
import { atualizarCondominio, buscarCondominioPorNome } from '../../../../api/backend-csi/condominios';
import { listaTemplatesNotificacao, enviaEmail } from '../../../../api/ezcale/notificacoes';
import { atualizaAdministradoraPorId } from '../../../../api/backend-csi/administradoras';

async function substituiVariaveisDeEmail(
    subject,
    template,
    cobrancas,
    nomeSindico,
    hash_cobranca_original,
    hashCarteiraId) {
    const parcelas = []
    cobrancas.map(cobranca => {
        if (cobranca?.descriptor.includes('Honorários') || cobranca?.descriptor.includes('Honorarios')) {
            //Não faz nada pois a Parcela de Honorários não é enviada para o Síndico
        }
        else {
            if (parcelas[cobranca.valor_total_cobranca]) {
                parcelas[cobranca.valor_total_cobranca]++
            }
            else {
                parcelas[cobranca.valor_total_cobranca] = 1;
            }
        }
    });

    let resumoAcordo = '';
    for (var key in parcelas) {
        if (parcelas[key] == 1) {
            resumoAcordo += `<li>${parcelas[key]}x Parcela de R$${key}</li>`;
        }
        if (parcelas[key] > 1) {
            resumoAcordo += `<li>${parcelas[key]}x Parcelas de R$${key}</li>`;
        }
    }
    const dadosCobranca = await getDetalhesDaCobranca(hash_cobranca_original);
    const dadosAcordo = await buscaDadosAcordoPorCarteira(hashCarteiraId);

    const variaveisCorpoEmail = {
        nome_sindico: nomeSindico,
        administradora: dadosCobranca[0].nome_credor,
        nome_devedor: dadosCobranca[0].nome_devedor,
        valor_total_cobranca: (parseFloat(dadosAcordo.acordo.valor_total_cobranca_original) - parseFloat(dadosAcordo.acordo.valor_honorarios_cobranca_original)).toFixed(2),
        acordo: `<ul>${resumoAcordo}</ul>`,
        link_acordo: window.location.origin + '/detalhesAcordo/' + hashCarteiraId,
        condominio: dadosCobranca[0].custom_fields.nome_condominio,
        unidade: dadosCobranca[0].custom_fields.unidade_devedora,
    };

    const corpoEmail = template.replace(/\[:(.*?)\:\]/g, (match, p1) => {
        return variaveisCorpoEmail[p1] !== undefined ? variaveisCorpoEmail[p1] : match;
    });

    const variaveisAssunto = {
        condominio: dadosCobranca[0].custom_fields.nome_condominio,
        unidade: dadosCobranca[0].custom_fields.unidade_devedora,
    };

    const assunto = subject.replace(/\[:(.*?)\:\]/g, (match, p1) => {
        return variaveisAssunto[p1] !== undefined ? variaveisAssunto[p1] : match;
    });

    return {
        corpoEmail,
        assunto
    }
}

const handleEnviarProposta = async (
    nomeDoSindico,
    emailDoSindico,
    dadosCondominio,
    setIsLoading,
    cobrancas,
    acordoId,
    corpoEmail,
    assuntoEmail,
    cobrancaOriginal,
    setAlerta,
    emailAdministradora,
    emailsCopia,
    administradoraId
) => {
    let dados = {
        nome_sindico: nomeDoSindico,
        email_sindico: emailDoSindico,
        email_administradora: emailAdministradora
    }
    setIsLoading(true);
    if (dadosCondominio?.id == undefined || dadosCondominio?.id == null) {
        const getDetalhesDeCobranca = await getDetalhesDaCobranca(cobrancas[0].hashInvoiceId);
        dados['nome_condominio'] = getDetalhesDeCobranca[0]['custom_fields']['nome_condominio'];
        if (acordoId > 0) {
            dados['acordo_id'] = acordoId;
        }
    }
    else {
        dados['id'] = dadosCondominio.id;
    }
    if (acordoId > 0) {
        const updateData = {
            id: acordoId,
            data_envio: new Date().toISOString().slice(0, 19).replace('T', ' '),
            status: 'Aguardando Aprovação'
        }
        await atualizaAcordo(updateData, cobrancaOriginal);
    }

    if (nomeDoSindico != '' && emailDoSindico != '') {
        atualizarCondominio(dados).then(async res => {
            if (administradoraId > 0) {
                await atualizaAdministradoraPorId(administradoraId, { email: emailAdministradora });
            }
            const recipient = { [nomeDoSindico]: emailDoSindico };
            await enviaEmail(corpoEmail, assuntoEmail, recipient, cobrancaOriginal);
            await criaEntradaNoHistoricoDaCobranca(cobrancaOriginal,
                'Proposta de Acordo Enviada para o Sindico ' + nomeDoSindico + ', no E-mail: ' + emailDoSindico);
            setAlerta({
                type: 'success',
                titulo: 'A Proposta de Acordo foi Enviada Com Sucesso!',
                descricao: ''
            });
            setIsLoading(false);
            window.location.reload();
        }).catch(err => {
            console.log('err', err);
            setAlerta({
                type: 'error',
                titulo: 'Um erro ocorreu',
                descricao: 'A Proposta de Acordo não foi enviada!'
            });
            setIsLoading(false);
        })
    }
    else {
        setAlerta({
            type: 'error',
            titulo: 'Um erro ocorreu',
            descricao: 'Preencha o nome e o e-mail do Síndico'
        });
        setIsLoading(false);
    }
}

const inicializaModal = async (hashInvoiceGroupId,
    setAcordoDetalhes,
    setAcordoId,
    setCobrancaOriginal,
    setDadosCondominio,
    setEmailDoSindico,
    setNomeDoSindico,
    dadosCondominio,
    setTemplatesDisponiveis,
    setRenderModal,
    cobrancas,
    setEmailAdministradora,
    setAdministradoraId
) => {
    const acordoDetails = await buscaDadosAcordoPorCarteira(hashInvoiceGroupId);
    if (acordoDetails?.acordo?.id) {
        setAcordoDetalhes(acordoDetails); //Utilizado para Renderizar o botão na Lista de Cobranças
        setAcordoId(acordoDetails.acordo.id);
        setCobrancaOriginal(acordoDetails.acordo.hash_cobranca_original);
        if (acordoDetails?.condominio) {
            setDadosCondominio(acordoDetails.condominio);
        }
        else {
            await getDadosCondominio(dadosCondominio, cobrancas, setNomeDoSindico, setEmailDoSindico)
        }
        if (acordoDetails?.condominio?.email_sindico) {
            setEmailDoSindico(acordoDetails.condominio.email_sindico);
        }
        if (acordoDetails?.condominio?.nome_sindico) {
            setNomeDoSindico(acordoDetails.condominio.nome_sindico);
        }
        if (acordoDetails?.condominio?.email_administradora) {
            setEmailAdministradora(acordoDetails.condominio.email_administradora);
        }
        if (acordoDetails?.condominio?.administradora_id) {
            setAdministradoraId(acordoDetails.condominio.administradora_id);
        }
    }
    else {
        await getDadosCondominio(dadosCondominio, cobrancas, setNomeDoSindico, setEmailDoSindico);
    }

    const templates = await listaTemplatesNotificacao();
    if (templates) {
        setTemplatesDisponiveis(templates);
    }
    setRenderModal(true);
};

const getDadosCondominio = async (dadosCondominio, cobrancas, setNomeDoSindico, setEmailDoSindico) => {
    if (dadosCondominio?.id == undefined || dadosCondominio?.id == null) {
        const getDetalhesDeCobranca = await getDetalhesDaCobranca(cobrancas[0].hashInvoiceId);
        const nome_condominio = getDetalhesDeCobranca[0]['custom_fields']['nome_condominio'];
        const detalhesCondominioPorNome = await buscarCondominioPorNome(nome_condominio, cobrancas[0].cpf_cnpj_credor);
        if (detalhesCondominioPorNome?.id) {
            setNomeDoSindico(detalhesCondominioPorNome?.nome_sindico);
            setEmailDoSindico(detalhesCondominioPorNome?.email_sindico);
        }
    }
}

export { substituiVariaveisDeEmail, handleEnviarProposta, inicializaModal };

