import axios from 'axios';
import { getBearerTokenConfig } from './auth';

async function listaTemplatesNotificacao() {

    const endpoint = localStorage.getItem('portalDomain') + '/api/v2' ?? process.env.REACT_APP_API_EZCALE;

    const listaTemplateData = {
        action: 'listnotificationtemplates',
    }
    try {
        const { data } = await axios.post(endpoint + "/notification.php",
            listaTemplateData,
            getBearerTokenConfig());
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return [];
    }
}

async function enviaEmail(htmlBody, subject, recipient, hashInvoiceId) {

    const portalDomain = localStorage.getItem('portalDomain');
    const endpoint = portalDomain + '/api/v2' ?? process.env.REACT_APP_API_EZCALE;

    const postNotificationData = {
        action: 'sendemailtoinvoicegroup',
        htmlBody,
        subject,
        recipient,
        hashInvoiceId,
        portalDomain: portalDomain.replace('https://', ''),
    }
    try {
        const { data } = await axios.post(endpoint + "/notification.php",
            postNotificationData,
            getBearerTokenConfig());
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return [];
    }
}

export {
    listaTemplatesNotificacao,
    enviaEmail
}