function retornaDataDeHojeAdicionando7Dias() {
    // Obter a data de hoje
    const data = new Date();

    // Adicionar 7 dias
    data.setDate(data.getDate() + 7);

    // Formatar a data para o formato "yyyy-mm-dd"
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Ajusta o mês para 2 dígitos
    const dia = String(data.getDate()).padStart(2, '0'); // Ajusta o dia para 2 dígitos

    return `${ano}-${mes}-${dia}`;
}

function subtraiXDiasDeHoje(x) {
    // Obter a data de hoje
    const data = new Date();

    // Adicionar 7 dias
    data.setDate(data.getDate() - x);

    // Formatar a data para o formato "yyyy-mm-dd"
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Ajusta o mês para 2 dígitos
    const dia = String(data.getDate()).padStart(2, '0'); // Ajusta o dia para 2 dígitos

    return `${ano}-${mes}-${dia}`;
}

function formataDataHoraMySQLParaDataBrasileira(dateString, subtractHours = 0) {
    if (dateString != null) {
        const [datePart, timePart] = dateString.split(" ");
        const [year, month, day] = datePart.split("-");
        let [hours, minutes, seconds] = timePart.split(":").map(Number);

        // Cria um objeto Date com a data e hora fornecidas
        let date = new Date(year, month - 1, day, hours, minutes, seconds);

        // Subtrai as horas se o parâmetro for maior que 0
        if (subtractHours > 0) {
            date.setHours(date.getHours() - subtractHours);
        }

        // Extrai os novos valores de dia, mês, ano, horas, minutos e segundos
        const newDay = String(date.getDate()).padStart(2, '0');
        const newMonth = String(date.getMonth() + 1).padStart(2, '0');
        const newYear = date.getFullYear();
        const newHours = String(date.getHours()).padStart(2, '0');
        const newMinutes = String(date.getMinutes()).padStart(2, '0');
        const newSeconds = String(date.getSeconds()).padStart(2, '0');

        return `${newDay}/${newMonth}/${newYear} ${newHours}:${newMinutes}:${newSeconds}`;
    }
    return '';
}

// Função para formatar as datas no formato aaaa-mm-dd
const formatDate = (date) => {
    return date.toISOString().split('T')[0]; // ISO string no formato yyyy-mm-dd
};

function datasParaTextoAgrupadoPorAno(datesString) {
    const monthNames = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    // Converte as datas do formato dd/mm/yyyy para objetos Date
    const datesArray = datesString.split(',').map(dateStr => {
        const [day, month, year] = dateStr.trim().split('/');
        return new Date(`${year}-${month}-${day}`);
    });

    const groupedDates = datesArray.reduce((acc, date) => {
        const year = date.getFullYear();
        const month = monthNames[date.getMonth()];

        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(month);

        return acc;
    }, {});

    let result = '';

    Object.keys(groupedDates).forEach((year, index, array) => {
        const months = groupedDates[year];
        const formattedMonths = months.length > 1 ?
            months.slice(0, -1).join(', ') + ' e ' + months.slice(-1) :
            months[0];

        result += `${formattedMonths} de ${year}`;

        if (index < array.length - 1) {
            result += ' e ';
        }
    });

    return result;
}




export {
    retornaDataDeHojeAdicionando7Dias,
    formataDataHoraMySQLParaDataBrasileira,
    formatDate,
    subtraiXDiasDeHoje,
    datasParaTextoAgrupadoPorAno,
}