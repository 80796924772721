import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getColorProfile } from '../../../../utils/appColors';
import { Button, TextField, Typography, Box, InputAdornment } from '@mui/material';
import Loader from '../../../../components/Loader/Loader';
import handleCriaSegundaVia from './SegundaViaService';
import { retornaDataDeHojeAdicionando7Dias } from '../../../../utils/dateFunctions';

const SegundaViaModal = ({ open, handleClose, cobranca }) => {
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alerta, setAlerta] = useState({}); //Objeto Alerta
  const [cobrancaData, setCobrancaData] = useState({
    vencimento: new Date().toISOString().substring(0, 10),
    // custom_honorarios_fee: 0.2 - Somente adicionado caso seja cobrança EB004
  });

  const {
    corPrincipalEscurecida } = getColorProfile();

  useEffect(() => {
    if (cobranca !== null && cobranca !== undefined) {
      const newCobranca = { ...cobranca };
      //Transforma dados recebidos da Cobrança
      newCobranca['logradouro_devedor'] = cobranca.buyerlogr;
      newCobranca['cep_devedor'] = cobranca.buyerpostal;
      newCobranca['cidade_devedor'] = cobranca.buyercity;
      newCobranca['estado_devedor'] = cobranca.buyerstate;
      newCobranca['numero_devedor'] = cobranca.buyernum;
      newCobranca['bairro_devedor'] = cobranca.buyerneigh;
      newCobranca['observacoes1'] = cobranca?.custom_fields?.observacoes1 ?? '';
      newCobranca['vencimento'] = retornaDataDeHojeAdicionando7Dias();
      newCobranca['custo_cobranca'] = cobranca?.custom_fields?.custo_cobranca ?? 0;
      newCobranca['valor_original'] = cobranca?.custom_fields?.valor_original ?? 0;
      // newCobranca['valor_original'] = 10; 
      newCobranca['valor_juros'] = cobranca?.custom_fields?.valor_juros ?? 0;
      newCobranca['valor_multa'] = cobranca?.custom_fields?.valor_multa ?? 0;
      newCobranca['correcao_monetaria_igpm'] = cobranca?.custom_fields?.correcao_monetaria_igpm ?? 0;
      newCobranca['custom_honorarios_fee'] = cobranca?.custom_fields?.custom_honorarios_fee ?? 0.2;
      newCobranca['valor_total_cobranca'] = (Number(cobranca?.valor_total_cobranca));

      if (cobranca?.custom_fields?.valor_total_honorarios) {
        newCobranca['valor_total_honorarios'] = Number(cobranca?.custom_fields?.valor_total_honorarios);
        newCobranca['custom_honorarios_fee'] = 0;
      }
      setCobrancaData(newCobranca);
    }

    //Valida o botão de Criação
    if (
      cobrancaData?.cpf_cnpj_adm === "" &&
      cobrancaData?.vencimento === ""
    ) {
      setIsCreateDisabled(true);
    }
    else {
      setIsCreateDisabled(false);
    }
  }, [cobranca]);

  const handleCobrancaData = (property, value) => {
    if (property === 'valor_total_honorarios') { //Edge Case pois modal modifica estrutura caso esse campo não exista
      if (isNaN(value) || value == null || value == undefined || value <= 0) {
        return;
      }
    }
    const updatedCobranca = { ...cobrancaData }
    updatedCobranca[property] = value;
    //Cobrança somente de Honorários
    if (updatedCobranca['valor_total_honorarios'] && updatedCobranca['valor_total_honorarios'] > 0) {
      updatedCobranca['valor_total_cobranca'] = Number(updatedCobranca['valor_total_honorarios']) 
      + Number(updatedCobranca['custo_cobranca']);
    }
    else { //Demais tipos de Cobrança
      const totalPreHonorarios = Number(updatedCobranca['valor_original']) +
      Number(updatedCobranca['valor_juros']) +
      Number(updatedCobranca['valor_multa']) +
      Number(updatedCobranca['correcao_monetaria_igpm']);
      updatedCobranca['valor_total_cobranca'] = Number((totalPreHonorarios * Number(updatedCobranca['custom_honorarios_fee']))
        + totalPreHonorarios + Number(updatedCobranca['custo_cobranca'])).toFixed(2);
    }
    setCobrancaData(updatedCobranca);
  }

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${open ? '' : 'hidden'}`}>
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-5 mx-3 overflow-auto max-h-[90vh]">
        <Typography id="modal-title" variant="h5" component="h5" sx={{ color: corPrincipalEscurecida }} >
          Criação de Segunda Via
        </Typography>
        <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-2" />
        <Typography
          variant="p"
          component="p"
          sx={{ color: corPrincipalEscurecida, mb: 2 }} >
          Será criada uma nova fatura dentro da mesma carteira com as mesmas informações da fatura original selecionada,
          salvas as alterações incluídas abaixo.
        </Typography>
        {cobrancaData['valor_total_honorarios'] && (
          <Typography id="modal-title" variant="h7" component="h7" sx={{ color: 'red' }} className='text-center' >
            Essa é uma Fatura 100% de Honorários (Essa configuração não pode ser alterada. Sendo assim, o Valor Original será sempre igual ao valor total de Honorários)
          </Typography>
        )}
        <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-5" />
        {cobrancaData['valor_total_honorarios'] ? (
          <Box className="mb-3">
            <TextField
              type="number"
              label="Valor Original (100% Honorários + Custo Cobrança)"
              value={cobrancaData.valor_total_honorarios}
              onChange={(e) => handleCobrancaData('valor_total_honorarios', e.target.value)}
              className="border rounded-lg w-full"
              slotProps={{
                inputLabel: { shrink: true },
                input: {
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>
                }
              }}
            />
          </Box>
        ) : (
          <Box className="mb-3">
            <TextField
              type="number"
              label="Valor Original (Sem Honorarios e Custo Cobrança)"
              value={cobrancaData?.valor_original}
              onChange={(e) => handleCobrancaData('valor_original', e.target.value)}
              className="border rounded-lg w-full"
              slotProps={{
                inputLabel: { shrink: true },
                input: {
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>
                }
              }}
            />
          </Box>
        )}

        <Box className="mb-3">
          <TextField
            type="date"
            label="Vencimento"
            value={cobrancaData.vencimento}
            onChange={(e) => handleCobrancaData('vencimento', e.target.value)}
            className="border rounded-lg w-full p-2 mb-5"
            slotProps={{
              inputLabel: { shrink: true }
            }}
          />
        </Box>
        {
          cobrancaData['valor_total_honorarios'] ? (
            <Box className="mb-3">
              <TextField
                type="number"
                disabled={true}
                label="Valor Total Honorários"
                value={cobrancaData['valor_total_honorarios']}
                className="border rounded-lg w-full"
                slotProps={{
                  inputLabel: { shrink: true },
                  input: {
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                  }
                }}
              />
            </Box>
          )
            :
            (
              <Box className="mb-3 flex">
                <TextField
                  type="number"
                  label="Honorários"
                  sx={{ width: '100%', color: corPrincipalEscurecida }}
                  value={cobrancaData?.custom_honorarios_fee * 100}
                  onChange={(e) => handleCobrancaData('custom_honorarios_fee', e.target.value / 100)}
                  className="border rounded-lg w-full"
                  slotProps={{
                    inputLabel: { shrink: true },
                    input: {
                      endAdornment: <InputAdornment position="start">%</InputAdornment>
                    }
                  }}
                />
              </Box>
            )
        }
        <Box className="mb-3 flex">
          <TextField
            type="number"
            label="Juros"
            sx={{ width: '30%', color: corPrincipalEscurecida, mr: '5%' }}
            value={cobrancaData?.valor_juros}
            onChange={(e) => handleCobrancaData('valor_juros', e.target.value)}
            className="border rounded-lg w-full p-2"
            slotProps={{
              inputLabel: { shrink: true },
              input: {
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }
            }}
          />
          <TextField
            type="number"
            label="Multa"
            sx={{ width: '30%', color: corPrincipalEscurecida, mr: '5%' }}
            value={cobrancaData?.valor_multa}
            onChange={(e) => handleCobrancaData('valor_multa', e.target.value)}
            className="border rounded-lg w-full p-2"
            slotProps={{
              inputLabel: { shrink: true },
              input: {
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }
            }}
          />
          <TextField
            type="number"
            label="Correção"
            sx={{ width: '30%', color: corPrincipalEscurecida }}
            value={cobrancaData?.correcao_monetaria_igpm}
            onChange={(e) => handleCobrancaData('correcao_monetaria_igpm', e.target.value)}
            className="border rounded-lg w-full p-2"
            slotProps={{
              inputLabel: { shrink: true },
              input: {
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }
            }}
          />
        </Box>
        <Box className="mb-3">
          <TextField
            type="number"
            label="Custo Cobrança"
            value={cobrancaData?.custo_cobranca}
            onChange={(e) => handleCobrancaData('custo_cobranca', e.target.value)}
            className="border rounded-lg w-full p-2"
            slotProps={{
              inputLabel: { shrink: true },
              input: {
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }
            }}
          />
        </Box>
        <Box className="mb-3">
          <TextField
            type="number"
            disabled={true}
            label="Valor Total da Cobrança (Valor Original + Custo + Honorários)"
            value={Number(cobrancaData?.valor_total_cobranca)}
            className="border rounded-lg w-full p-2"
            slotProps={{
              inputLabel: { shrink: true },
              input: {
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }
            }}
          />
        </Box>
        <Box className="mb-2">
          <TextField
            type="text"
            label="Observação"
            value={cobrancaData?.observacoes1}
            onChange={(e) => handleCobrancaData('observacoes1', e.target.value)}
            className="border rounded-lg w-full"
            slotProps={{
              inputLabel: { shrink: true }
            }}
          />
        </Box>

        <hr className='mb-2' />
        {/* Mostra Alerta de Resultado do Processamento */}
        {Object.keys(alerta).length > 0 && (
          <Alert className="signin-area mb-1 mt-1" severity={alerta.type}>
            <AlertTitle>{alerta.titulo}</AlertTitle>
            {alerta.descricao}
          </Alert>)}

        {/* Botões de Ação */}
        {!isLoading ? (
          <div className="flex justify-end space-x-4 mt-5">
            <Button
              sx={{
                background: "linear-gradient(to right, #f87171, #ef4444)", // Botão cancelar vermelho
                color: "white",
                borderRadius: "6px", // Border radius de 6px
                height: "35px",
                mr: 1,
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              disabled={isCreateDisabled}
              onClick={() => handleCriaSegundaVia(cobrancaData, setIsLoading, setAlerta)}
              sx={{
                background: "linear-gradient(to right, #66bb6a, #43a047)", // Botão criar
                color: "white",
                borderRadius: "6px", // Border radius de 6px
                height: "35px",
              }}
            >
              Criar
            </Button>
          </div>)
          :
          (
            <Box className="w-full mt-5" sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Loader isLoading={isLoading} />
            </Box>
          )}

      </div>
    </div >
  );
};

export default SegundaViaModal;
