import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getColorProfile } from '../../../../utils/appColors';
import { Button, TextField, Typography, Box, Autocomplete, useMediaQuery } from '@mui/material';
import Loader from '../../../../components/Loader/Loader';
import { substituiVariaveisDeEmail, handleEnviarProposta, inicializaModal } from './EnviarPropostaDeAcordoService';
import CircularLoaderWithCenterLogo from '../../../../components/Loader/CircularLoaderWithCenterLogo';
import { useTheme } from '@emotion/react';

const EnviarPropostaDeAcordoModal = ({
  open,
  handleClose,
  hashCarteiraId,
  cobrancas,
  setAcordoDetalhes }) => {
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [renderModal, setRenderModal] = useState(false);
  const [alerta, setAlerta] = useState({}); //Objeto Alerta
  const [emailDoSindico, setEmailDoSindico] = useState('');
  const [emailAdministradora, setEmailAdministradora] = useState('');
  const [emailsCopia, setEmailsCopia] = useState([]);
  const [nomeDoSindico, setNomeDoSindico] = useState('');
  const [dadosCondominio, setDadosCondominio] = useState({});
  const [corpoEmail, setCorpoEmail] = useState('');
  const [assuntoEmail, setAssuntoEmail] = useState('');
  const [templatesDisponiveis, setTemplatesDisponiveis] = useState('');
  const [cobrancaOriginal, setCobrancaOriginal] = useState('');
  const [acordoId, setAcordoId] = useState(0);
  const [administradoraId, setAdministradoraId] = useState(0);
  const {
    corPrincipalEscurecida } = getColorProfile();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (emailDoSindico !== '') {
      setIsCreateDisabled(false);
    }
    inicializaModal(hashCarteiraId,
      setAcordoDetalhes,
      setAcordoId,
      setCobrancaOriginal,
      setDadosCondominio,
      setEmailDoSindico,
      setNomeDoSindico,
      dadosCondominio,
      setTemplatesDisponiveis,
      setRenderModal,
      cobrancas,
      setEmailAdministradora,
      setAdministradoraId);
  }, [hashCarteiraId]);

  useEffect(() => {
    if (nomeDoSindico !== '' &&
      emailDoSindico !== '' &&
      emailAdministradora !== '' &&
      corpoEmail !== '') {
      setIsCreateDisabled(false);
    }
  }, [emailDoSindico, corpoEmail, assuntoEmail]);


  const handleTemplateSelect = async (event, value) => {
    const { corpoEmail, assunto } = await substituiVariaveisDeEmail(value.title, value.val, cobrancas, nomeDoSindico, cobrancaOriginal, hashCarteiraId);
    setCorpoEmail(corpoEmail);
    setAssuntoEmail(assunto);
  };

  const handlEmailsCopia = async (value, index, field) => {
    const newEmailsCopia = [...emailsCopia];
    newEmailsCopia[index][field] = value;
    setEmailsCopia(newEmailsCopia);
  };

  const adicionaCampoEmailCopia = () => {
    const newEmailsCopia = [...emailsCopia]
    newEmailsCopia.push({
      nome: '',
      email: ''
    });
    setEmailsCopia(newEmailsCopia);
  }

  const renderEmailsCopia = () => {
    return emailsCopia.map((copia, index) => (
      <Box className="mb-3 mt-3 w-full">
        <TextField
          type="text"
          label="Nome"
          value={copia.nome}
          onChange={(e) => handlEmailsCopia(e.target.value, index, 'nome')}
          className="border rounded-lg"
          sx={{ width: isMobile ? '100%' : '25%', marginRight: '1%', color: corPrincipalEscurecida }}
          slotProps={{
            inputLabel: { shrink: true }
          }}
        />
        <TextField
          type="text"
          label="E-mail"
          value={copia.email}
          onChange={(e) => handlEmailsCopia(e.target.value, index, 'email')}
          className="border rounded-lg"
          sx={{ width: isMobile ? '100%' : '35%', color: corPrincipalEscurecida }}
          slotProps={{
            inputLabel: { shrink: true }
          }}
        />
      </Box>
    ));
  }

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 w-full ${open ? '' : 'hidden'}`}>
      <div className="bg-white rounded-lg shadow-lg w-full p-5 mx-3 overflow-auto max-h-[90vh]">
        {renderModal ? (
          <div>
            <Typography id="modal-title" variant="h5" component="h5" sx={{ color: corPrincipalEscurecida }} >
              Enviar Proposta de Acordo
            </Typography>
            <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-2" />
            <Typography
              variant="p"
              component="p"
              sx={{ color: corPrincipalEscurecida, mb: 2 }} >
              Preencha o E-mail do Síndico e Template de E-mail para enviar a proposta de Acordo para o Síndico
            </Typography>
            <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-5" />
            <Box className="mb-3">
              <TextField
                type="text"
                label="Nome do Síndico"
                value={nomeDoSindico}
                onChange={(e) => setNomeDoSindico(e.target.value)}
                className="border rounded-lg w-full"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
              />
            </Box>
            <Box className="mb-3">
              <TextField
                type="text"
                label="E-mail do Síndico"
                value={emailDoSindico}
                onChange={(e) => setEmailDoSindico(e.target.value)}
                className="border rounded-lg w-full"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
              />
            </Box>
            <Box className="mb-3">
              <TextField
                type="text"
                label="E-mail da Administradora"
                value={emailAdministradora}
                onChange={(e) => setEmailAdministradora(e.target.value)}
                className="border rounded-lg w-full"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
              />
            </Box>
            <Box
              className="mb-5 cursor-pointer"
              onClick={() => adicionaCampoEmailCopia()}>
              <AddCircleIcon /> Adicionar Cópia
            </Box>
            {emailsCopia.length > 0 && renderEmailsCopia()}
            <Box className="mb-3">
              <Autocomplete
                options={templatesDisponiveis}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option, index) => `${option.name}-${index}`}
                onChange={handleTemplateSelect}
                sx={{ width: '100%' }}
                renderInput={(params) =>
                  (<TextField {...params} label="Template de E-mail" variant="outlined" />)}
              />
            </Box>
            <Box className="mb-3">
              <TextField
                type="text"
                label="Assunto E-mail"
                value={assuntoEmail}
                onChange={(e) => setAssuntoEmail(e.target.value)}
                className="border rounded-lg w-full"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
              />
            </Box>
            <Box className="mb-3">

            </Box>
            <Box className="mb-3">
              <Typography
                className='justify-center align-center'
                variant="p"
                component="p"
                sx={{ color: corPrincipalEscurecida, mb: 2 }} >
                Preview do E-mail (Pode ser editado)
              </Typography>
              <ReactQuill theme="snow" value={corpoEmail} onChange={setCorpoEmail} />
            </Box>
            <hr className='mt-2 mb-2' />
            {/* Mostra Alerta de Resultado do Processamento */}
            {Object.keys(alerta).length > 0 && (
              <Alert className="signin-area" severity={alerta.type}>
                <AlertTitle>{alerta.titulo}</AlertTitle>
                {alerta.descricao}
              </Alert>)}

            {/* Botões de Ação */}
            {!isLoading ? (
              <div className="flex justify-end space-x-4 mt-2">
                <Button
                  sx={{
                    background: "linear-gradient(to right, #f87171, #ef4444)", // Botão cancelar vermelho
                    color: "white",
                    borderRadius: "6px", // Border radius de 6px
                    height: "35px",
                    mr: 1,
                  }}
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={isCreateDisabled}
                  onClick={() => handleEnviarProposta(nomeDoSindico, emailDoSindico,
                    dadosCondominio,
                    setIsLoading,
                    cobrancas,
                    acordoId,
                    corpoEmail,
                    assuntoEmail,
                    cobrancaOriginal,
                    setAlerta,
                    emailAdministradora,
                    emailsCopia,
                    administradoraId)}
                  sx={{
                    background: "linear-gradient(to right, #66bb6a, #43a047)", // Botão criar
                    color: "white",
                    borderRadius: "6px", // Border radius de 6px
                    height: "35px",
                  }}
                >
                  Enviar Proposta
                </Button>
              </div>)
              :
              (
                <Box className="w-full mt-5" sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Loader isLoading={isLoading} />
                </Box>
              )}
          </div>
        ) : (
          <CircularLoaderWithCenterLogo />
        )}
      </div>
    </div>
  );
};

export default EnviarPropostaDeAcordoModal;
