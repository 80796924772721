import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button, CircularProgress } from "@mui/material";
import { getColorProfile } from "../../utils/appColors";
import { atualizaStatusAcordo } from './DetalhesAcordoService';

const ReprovacaoModal = ({ open, onClose, acordo, condominio }) => {
  const [contraProposta, setContraProposta] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { corPrincipal,
    corPrincipalEscurecida } = getColorProfile();



  return (
    <Modal open={open} onClose={onClose}>
      <Box
        className="bg-white p-6 rounded-lg shadow-lg"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" sx={{ color: corPrincipalEscurecida }}>
          <b>Rejeitar Acordo</b>
        </Typography>
        <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-5" />

        <Typography variant="h6" className="mb-5" sx={{ color: corPrincipalEscurecida }}>
          Explique o motivo da Rejeição ou faça sua sugestão de Contra Proposta
        </Typography>
        <TextField
          sx={{ marginTop: '10px' }}
          label="Contra Proposta / Motivo"
          multiline
          minRows={5}
          maxRows={500}
          fullWidth
          value={contraProposta}
          onChange={(e) => setContraProposta(e.target.value)}
        />
        <Box className="flex justify-end space-x-4 mt-4">
          <div className="flex justify-center gap-4 mt-1 w-full max-w-3xl">
            <Button
              variant="contained"
              onClick={onClose}
              sx={{
                background: "linear-gradient(to right, #E6BE4C, #EADB75)", // Botão cancelar vermelho
                color: "black",
                borderRadius: "6px", // Border radius de 6px
              }}
              className="w-30 h-12 text-lg"
            >
              Cancelar
            </Button>
            <Button
              disabled={contraProposta === ''}
              onClick={() => atualizaStatusAcordo('Reprovado', setIsLoading, acordo, condominio, contraProposta)}
              variant="contained"
              sx={{
                background: "linear-gradient(to right, #66bb6a, #43a047)", // Botão criar
                color: "white",
                borderRadius: "6px", // Border radius de 6px
              }}
              className="w-[280px] h-12 text-lg"
            >
              {!isLoading ? ('Enviar') : <CircularProgress sx={{ color: corPrincipal }} />}
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReprovacaoModal;
